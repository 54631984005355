import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/template', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/template/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/core/template/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTemplate(ctx, {templateData, type_id} ) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/core/template/${type_id}`, templateData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, { 
            params: { deep: deep, parent: parent }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTemplate(ctx, { id, type_id, templateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/core/template/${id}/${type_id}`, templateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
